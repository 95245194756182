<template>
  <router-link
    v-if="type !== 'liquidity_pool_shares'"
    :to="{
      name: 'AssetDetailsRoute',
      params: {
        asset_code: asset.code,
        asset_issuer: asset.issuer,
      },
    }"
  >
    <slot v-if="$slots.default"></slot>
    <template v-else>{{ asset.code }}</template>
  </router-link>
  <template v-else> {{ asset.code }} </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "AssetDetailsLink",
  props: {
    type: [String, Number],
    code: String,
    issuer: String,
    sep11: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const asset = computed(() => {
      if (props.sep11) {
        if (props.sep11 === "native") {
          return {
            code: t("asset_details_link.xlm"),
            issuer: t("asset_details_link.native"),
          };
        } else {
          const asset = props.sep11.split(":");
          return { code: asset[0], issuer: asset[1] };
        }
      }

      if (props.type === "native") {
        return {
          code: t("asset_details_link.xlm"),
          issuer: t("asset_details_link.native"),
        };
      } else if (props.type === "liquidity_pool_shares") {
        return { code: t("asset_details_link.liquidity_pool_shares") };
      } else {
        return { code: props.code, issuer: props.issuer };
      }
    });
    return { asset };
  },
});
</script>
