<template>
  <div class="autocomplete-form w-100 rounded border"
    @keydown.down="selectedIndex = selectedIndex < valuesShowing.length - 1 ? selectedIndex + 1 : selectedIndex;"
    @keydown.up="selectedIndex = selectedIndex > - 1 ? selectedIndex - 1 : selectedIndex;"
    @keydown.enter="onEnterClicked">
    <div class="w-100 autocomplete-form-input-elements" style="display: flex;">
      <span class="input-group-text border-0 bg-body" style="position:absolute"><i class="bi bi-search"></i></span>
      <input id="autocomplete-form-input" v-model="searchInput"
        class="form-control border-0 px-5" autocomplete="off" :placeholder="$t('search.placeholder_text')" type="text"
        @input="onInputHandler" @keyup.enter="search">
      <button  v-if="searchInput.length > 0" style="position:absolute;right: 0" class="btn border-0" type="button" @click="clearSearch">
        <i class="bi bi-x-circle"></i>
      </button>
    </div>
    <div class="list-group list-group-flush autocomplete-form-items mt-1 rounded border" :class="{hide: !visible || searchInput.length == 0}">
      <div v-if="loading" class="p-2 bg-body text-center"> 
        <div class="spinner-border text-muted" role="status">
        </div> 
      </div>
      <button v-for="(val, index) in valuesShowing" :key="index" :data-id="index"
        style="font-size: 14px;text-align: left;" class="list-group-item list-group-item-action"
        :class="{active: selectedIndex == index}" @click="onClickHandler" @mouseover="selectedIndex = index">
        <span v-if="val.logo && val.type == 'asset'" class="p-1">
          <img :src="val.logo" width="25" height="25" class="rounded-circle" />
        </span>
        <span v-else class="p-1">
          <img width="25" height="25" src="@/assets/images/placeholder.jpeg" class="rounded-circle" />
        </span>
        <span>
        {{ val.asset_code }}
        <small v-if="val.label" class="badge bg-primary" >
            {{ val.label}} 
            <i class="ms-1 bi bi-patch-check-fill text-white text-truncate" 
               data-bs-toggle="tooltip" 
               data-bs-placement="top" 
               data-bs-original-title="Verified Account">
            </i>
        </small> 
        <small 
            v-else-if="val.asset_issuer" 
            v-middle-ellipsis="{size: 15, text: '-' + val.asset_issuer}">
            - {{ val.asset_issuer }} 
        </small> 
        <small class="text-uppercase float-end">{{ val.type }}</small>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import _debounce from 'lodash/debounce';

export default defineComponent({
  name: "Search",
  setup() {
    const router = useRouter();
    const store = useStore();
    const searchInput = ref("");
    const valuesShowing = ref([]);
    const visible = ref(false);
    const selectedIndex = ref(0);
    const loading = ref(false);

    const onClickHandler = () => {
      let valueShowing = valuesShowing.value[selectedIndex.value];
      visible.value = false;
      selectedIndex.value = 0;

      if (valueShowing.type == 'account') {
        router.push({
          name: "AccountDetailsRoute",
          params: {
            acc_id: valueShowing.asset_issuer,
          },
        });
      } else if (valueShowing.type == 'contract') {
        router.push({
          name: "ContractDetailsRoute",
          params: {
            contract_id: valueShowing.contract_id,
          },
        });
      } else {
        router.push({
          name: "AssetDetailsRoute",
          params: {
            asset_code: valueShowing.asset_code,
            asset_issuer: valueShowing.asset_issuer,
          },
        });
      }

    };

    const onInputHandler = _debounce(() => {
      if (searchInput.value.length > 0) {
        visible.value = true;
        loading.value = true;
        store.dispatch("settings/search", {search: searchInput.value}).then((response) => {
          if (response) {
            let assetsParsed = response.data.map((asset) => {
              if (asset.accountId) {
                return {
                  'asset_code': asset.asset_code,
                  'asset_issuer': asset.accountId,
                  'logo': null,
                  'type': 'account',
                  'label': asset.label_name
                };
              } else if (asset.contract_id) {
                return {
                  'asset_code': asset.asset_code,
                  'asset_issuer': asset.asset_issuer,
                  'contract_id': asset.contract_id,
                  'logo': null,
                  'type': 'contract',
                  'label': asset.account?.label?.name
                };
              } else {
                return {
                  'asset_code': asset.asset_code,
                  'asset_issuer': asset.asset_issuer,
                  'logo': asset.image,
                  'type': 'asset',
                  'label': asset.account?.label?.name
                }
              }
            });
            valuesShowing.value = assetsParsed;
          }
          loading.value = false;
        });
      } else {
        valuesShowing.value = [];
      }
    }, 700);

    const search = () => {
      if (searchInput.value.includes("-")) {
        let asset = searchInput.value.split("-");
        router.push({
          name: "AssetDetailsRoute",
          params: {
            asset_code: asset[0],
            asset_issuer: asset[1],
          },
        });
      } else if (
        searchInput.value.length > 0 &&
        /^\d+$/.test(searchInput.value) &&
        searchInput.value.length <= 10
      ) {
        router.push({
          name: "LedgerDetailsRoute",
          params: {
            ledger_sequence: searchInput.value,
          },
        });
      } else if (
        searchInput.value.length > 10 &&
        /^\d+$/.test(searchInput.value) &&
        searchInput.value.length <= 30
      ) {
        router.push({
          name: "OpDetailsRoute",
          params: {
            op_id: searchInput.value,
          },
        });
      } else if ([69, 56].includes(searchInput.value.length)) {
        router.push({
          name: "AccountDetailsRoute",
          params: {
            acc_id: searchInput.value,
          },
        });
      } else if (searchInput.value.length === 64) {
        store.dispatch("contracts/fetchContractById", searchInput.value).then((response) => {
          if (response) {
            router.push({
              name: "ContractDetailsRoute",
              params: {
                contract_id: searchInput.value,
              },
            });
          } else {
            router.push({
              name: "TxnDetailsRoute",
              params: {
                txn_hash: searchInput.value,
              },
            });
          }
        });
      } else {
        router.push({
          name: "DirectoryRoute",
          query: {
            label: searchInput.value,
          },
        });
      }
    };
    const clearSearch = () => {
        visible.value = false;
        searchInput.value = '';
    }
    return {clearSearch, search, searchInput, onInputHandler, onClickHandler, valuesShowing, visible, selectedIndex, loading};
  },
});
</script>
